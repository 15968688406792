import React from "react";
import logo from '../../assets/img/logo/logo-slogan-vertical-branca.png'

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {
    /**
     * Your ajax will goes here to get data then call setState
     */
  }

  render() {
    return (
      <React.Fragment>
        <footer className="footer-section">
          <div
            className={"footer-top background-img-2 " + 'py-5'} 
            style={{
              backgroundColor: 'rgba(18, 73, 32, 0.95)'
            }}
          >
            <div className="container">
              <div className="row justify-content-between">
                <div className="col-lg-4 mb-3 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <img
                      src={logo}
                      alt="footer logo"
                      width="220"
                      className="img-fluid mb-3"
                    />

                    <div className="social-list-wrap">
                      <ul className="social-list list-inline list-unstyled">
                        <li className="list-inline-item">
                          <a href="https://facebook.com/agrareoficial" target="_blank" title="Facebook">
                            <span className="ti-facebook"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://www.instagram.com/agrareoficial" target="_blank" title="Instagram">
                            <span className="ti-instagram"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://www.linkedin.com/company/agrareoficial" target="_blank" title="LinkedIn">
                            <span className="ti-linkedin"></span>
                          </a>
                        </li>
                        <li className="list-inline-item">
                          <a href="https://www.youtube.com/channel/UCWKYGh52iJsFRNSyZXvr_MQ" target="_blank" title="YouTube">
                            <span className="ti-youtube"></span>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Outros Links</h5>
                    <ul className="list-unstyled">
                      {/* <li className="mb-2">
                        <a href="https://www.blog.agrare.com.br">Blog</a>
                      </li> */}
                      <li className="mb-2">
                        <a href="/suporte">Suporte</a>
                      </li>
                      <li className="mb-2">
                        <a href="/termos">Termos de Uso</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-lg-4 ml-auto mb-4 mb-lg-0">
                  <div className="footer-nav-wrap text-white">
                    <h5 className="mb-3 text-white">Contatos</h5>
                    <ul className="list-unstyled support-list">
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-location-pin mr-2"></span>
                        Rua Ângelo Preto, 66, Centro
                        <br />
                        Passo Fundo/RS
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-mobile mr-2"></span>{" "}
                        (54) 9 9937-0972
                      </li>
                      <li className="mb-2 d-flex align-items-center">
                        <span className="ti-email mr-2"></span>
                        <a href="mailto:contato@agrare.com.br"> contato@agrare.com.br</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="footer-bottom gray-light-bg pt-4 pb-4">
            <div className="container">
              <div className="row text-center justify-content-center">
                <div className="col-md-6 col-lg-5">
                  <p className="copyright-text pb-0 mb-0">
                  Agrare - Tecnologia e Gestão no Campo | CNPJ 35.842.386/0001-63 © {(new Date().getFullYear())} | Todos os direitos reservados
                  </p>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </React.Fragment>
    );
  }
}

export default Footer;
